<template>
  <div class="content-body">
    <div class="row mt-2 mg-sm-0 no-print">
      <div class="col-10">
        <div class="content-header pd-l-0">
          <div>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  Human Resources
                </li>
                <!-- <li class="breadcrumb-item">
                  <router-link to="/human-resources/employee/profile">Employee-Profile</router-link>
                </li> -->
                <li class="breadcrumb-item">
                  <router-link to="/human-resources/attendance-day">Attendance</router-link>
                </li>
                <li
                  class="breadcrumb-item text-capitalize active"
                  aria-current="page"
                >
                  Individual Attandence
                </li>
              </ol>
            </nav>
            <h4 class="content-title content-title-xs">Attendance Report: #{{id}}</h4>
          </div>
        </div>
        <!-- content-header -->
      </div>
      <!-- col -->
    </div>
    <!-- row -->
    <div class="bg-theam-secondary table-search-head pt-0">
      <div class="row">
        <div class="filter-input col-md-9 d-block mr-0 pr-0">
          <select name="size" class="filter-input mr-2 mg-t-5" v-model="individualReportParams.month"  @change.prevent="filterByMonth">
            <option value="1">January</option>
            <option value="2">February</option>
            <option value="3">March</option>
            <option value="4">April</option>
            <option value="5">May</option>
            <option value="6">June</option>
            <option value="7">July</option>
            <option value="8">August</option>
            <option value="9">September</option>
            <option value="10">October</option>
            <option value="11">November</option>
            <option value="12">December</option>
          </select>
        </div>
        <!-- <div class="col-md-3 ml-0 pl-0 mg-t-5">
          <input type="text" class="float-right" placeholder="Search..." />
        </div> -->
      </div>
    </div>
    <div class="row py-0">
      <div class="col-md-12 overflow-x-auto">
        <table class="table table2 table-hover" id="exampleTable">
          <thead>
            <tr>
              <th class="wd-5p table-start-item">Day</th>
              <th class="wd-10p">Date</th>
              <th class="wd-25p">Attended By</th>
              <th class="wd-10p">Attendance</th>
              <th class="wd-10p">Work ime</th>
              <th class="wd-10p ">In-Time</th>
              <th class="wd-10p ">Out-Time</th>
              <th class="wd-20p ">Remark</th>
            </tr>
          </thead>
          <tbody v-if="!loading && dataLists.length != 0">
            <tr v-for="(items, key, index) in dataLists" :key="index">
              <th scope="row" class="table-start-item">{{++index}}</th>
              <td>{{ key }}</td>
              <td>{{items.attended_by}}</td>
              <td>
                <span v-if="items.attendence_status == null">
                  <span class="badge badge-warning">Holiday</span>
                </span>
                <span v-if="items.attendence_status == 'present'">
                  <span class="badge badge-success">Present</span>
                </span>
                <span v-if="items.attendence_status == 'absent'">
                  <span class="badge badge-danger">Absent</span>
                </span>
              </td>
              <td ><span v-if="items.attendence_status">{{items.work_time}}</span></td>
              <td ><span v-if="items.attendence_status">{{items.in_time}}</span></td>
              <td><span v-if="items.attendence_status">{{items.out_time}}</span></td>
              <td><span v-if="items.attendence_status">{{items.remark}}</span></td>
            </tr>
          </tbody>
           <tbody v-else-if="!loading && dataLists.length == 0">
            <tr>
              <td colspan="11" style="text-align:center;">No records found.</td>
            </tr>
          </tbody>
          <tbody v-else-if="loading">
            <tr>
              <td colspan="11" style="text-align:center;">Loading.....</td>
            </tr>
          </tbody>
          <tbody v-else-if="error">Error Fetching data from the server.</tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Services from "../Services/Services";
import { Search } from "../../../../../mixins/search";
export default {
  mixins: [Search],
  data(){
    return {
      id:'',
      month:'',
      employee_id:'',
      employee_name:'',
      loading:false,
    }
  },
  computed:{
    ...mapGetters([
      "processing",
      "eventMessage",
      "dataLists",
      "dataLists2",
      "dataLists4",
      "dataId",
      "modalId",
      "modalMode",
      "today",
    ]),
    ...mapGetters("employees",["individualReportParams"])
  },
  beforeMount(){
     if(!this.individualReportParams){
        let individual_params={
          month:new Date().getMonth()+1,
          year: new Date().getFullYear(),
          id:this.$route.params.id,
        };
        this.$store.commit("employees/setIndividualReportParams",individual_params);
      }
  },
  methods:{
    getData(){
      //this.dataLists = [];
      this.loading = true;
      //this.$store.commit("getData", `api/attendence/individual/report`);
      Services.getMonthlyIndividualReport(this.individualReportParams).then(res=>{
          this.$store.commit("setDataLists",res.data.data);      
      }).catch(err=>{
        console.log(err);
      }).finally(()=>{
        this.loading = false;
      }); 
    },
    filterByMonth(){
       this.getData();
    },
    employeeDetails(){
       this.$store.commit("getData4", `api/attendence/individual/details/${this.id}`);
       this.dataLists4.filter(function (item) {
          this.employee_id = item.employee_id;
          this.employee_name = item.name;
      });
    }
  },
  mounted(){
    this.id = this.$route.params.id;
    this.getData();
  },
  beforeUpdate(){
    this.employeeDetails()
  }
};
</script>
<style scoped>
</style>